export const routeConfig = {
  home: { label: 'Forsiden', url: '/' },
  kjopeBolig: {
    label: 'Kjøpe eiendom',
    url: 'https://privatmegleren.no/kjope-bolig?q='
  },
  nybygg: { label: 'Nybygg', url: 'https://privatmegleren.no/nybygg' },
  naering: { label: 'Næring', url: 'https://privatmegleren.no/naering' },
  valuation: {
    label: 'Selge eiendom / Verdivurdering',
    url: 'https://privatmegleren.no/verdivurdering-selge-eiendom'
  },
  kontoreriNaerheten: {
    label: 'Kontorer i nærheten',
    url: 'https://privatmegleren.no/kontorer/inarheten?q='
  },
  officeBrokerSearch: {
    label: 'Kontor / Megler',
    url: 'https://privatmegleren.no/kontorer?alle'
  },
  raadvedsalg: {
    label: 'Råd ved salg av eiendom',
    url: 'https://privatmegleren.no/content/selge-bolig-tips-rad'
  },
  hvaeretaks: {
    label: 'Hva er e-takst?',
    url: 'https://privatmegleren.no/content/e-takst-nokkelen-til-boligsalg'
  },
  naarlonnerdetseg: {
    label: 'Når lønner det seg å selge?',
    url: 'https://privatmegleren.no/content/kjope-eller-selge-forst'
  },
  boligbytte: {
    label: 'PrivatMegleren Boligbytte',
    url: 'https://privatmegleren.no/boligbytte-selge-eiendom'
  },
  kjop: {
    label: 'PrivatMegleren KJØP',
    url: 'https://privatmegleren.no/kjope-eiendom'
  },
  mittboligsalg: {
    label: 'PrivatMegleren Mitt eiendomsalg',
    url: 'https://privatmegleren.no/mittboligsalg'
  },
  kontorinaerheten: {
    label: 'Kontorer i nærheten',
    url: 'https://privatmegleren.no/kontorer/inarheten?q='
  },
  klikk: {
    label: 'PrivatMegleren KLiKK',
    url: 'https://privatmegleren.no/klikk'
  },
  notis: {
    label: 'PrivatMegleren Notis',
    url: 'https://notis.privatmegleren.no'
  },
  settprispaa: {
    label: 'PrivatMegleren Sett Pris På',
    url: 'https://privatmegleren.no/verdivurdering-boligkalkulator'
  },
  omoss: {
    label: 'Om oss',
    url: 'https://privatmegleren.no/om-oss'
  },
  boligsok: {
    label: 'PM Eiendomssøk',
    url: 'https://privatmegleren.no/boligsok'
  },
  finansiering: {
    label: 'Finansiering',
    url: 'https://privatmegleren.no/finansiering-boliglan'
  },
  personvern: {
    label: 'Personvern',
    url: 'https://privatmegleren.no/personvern'
  },
  spoross: {
    label: 'Spør oss',
    url: 'https://privatmegleren.no/content/sporross'
  },
  stillinger: {
    label: 'Stillinger',
    url: 'https://privatmegleren.no/stillinger'
  },
  digitalMeeting: {
    label: 'Book et digitalt møte',
    url: 'https://privatmegleren.no/digitalt-mote-eiendom-salg'
  },
  tegnekonkurranse: {
    label: 'Drømmehytten',
    url: 'https://privatmegleren.no/kampanje/geilo/tegnekonkurranse'
  },
  boligreise: {
    label: 'BoligReise',
    url: 'https://privatmegleren.no/leveransebeskrivelse-selge-bolig'
  },
  raadgivning: {
    label: 'Rådgivning',
    url: 'https://privatmegleren.no/kampanje/raadgivning/selge-eller-kjope-leilighet-forst'
  },
  veientilsolgt: {
    label: 'Veien til solgt',
    url: 'https://privatmegleren.no/kampanje/veien-til-solgt'
  },
  nordea_finansiering: {
    label: 'Kontakt en Nordea-rådgiver',
    url: 'https://www.nordea.no/privat/vare-produkter/lan-og-kreditt/boliglan/privatmegleren-og-nordea-kontaktskjema.html?cid=partner-plxckhlhei'
  },
  nordea_finansiering_2: {
    label: 'Søk finansiering hos Nordea',
    url: 'https://www.nordea.no/privat/vare-produkter/lan-og-kreditt/boliglan/privatmegleren-og-nordea-finansiering.html?cid=partner-14b3mzmxwe'
  },
  nordea_finansiering_3: {
    label: 'Få tilbud på boliglån',
    url: 'https://www.nordea.no/privat/vare-produkter/lan-og-kreditt/boliglan/jeg-onsker-a-kjope-en-bolig.html?cid=partner-f4gv1ruzzn'
  },
  kvadrat: {
    label: 'Kvadrat',
    url: 'https://privatmegleren.no/kampanje/kvadrat'
  },
  presisjon: {
    label: 'Presisjon',
    url: 'https://privatmegleren.no/kampanje/presisjon'
  },
  detaljene_som_selger: {
    label: 'Detaljene som selger',
    url: 'https://privatmegleren.no/kampanje/detaljene-som-selger'
  },
  forstegangsselgeren: {
    label: 'Førstegangsselgeren',
    url: 'https://privatmegleren.no/kampanje/forstegangsselgeren'
  }
};

export type RouteConfig = typeof routeConfig;
